<template>
<div class="field-settings rounded-xl text-base-content">
  <div
    class="field-settings__header bg-primary text-white-text px-3 py-2 font-bold"
  >
    {{ fieldLabel }}
  </div>
  <div v-if="isLoading" class="flex items-center justify-center p-5 w-full">
    <Loader :loading="true" :size="12" />
  </div>
  <div
    v-else-if="fieldData && fieldData.data.field_options.length"
    class="field-settings__body bg-gray-100 font-bold"
  >
    <OptionDropdown
      ref="option"
      class="field-settings__option border-gray-200"
      v-for="option in fieldData.data.field_options"
      :key="option.id"
      :option="option"
      :field="field"
      :selectedFieldId="selectedFieldId"
      :getDependentsMethod="getDependentsMethod"
      :getOptionsMethod="getOptionsMethod"
      :searchString="searchString"
      v-on="$listeners"
    />
  </div>
</div>
</template>

<script>
import Loader from "@shared/loader";
import OptionDropdown from "./option-dropdown";

export default {
  name: 'cascaded-fields-list',
  components: {
    Loader,
    OptionDropdown,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    selectedFieldId: {
      type: String,
    },
    getDependentsMethod: {
      type: Function,
    },
    getOptionsMethod: {
      type: Function,
    },
    searchString: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      fieldData: null,
    }
  },
  computed: {
    fieldLabel() {
      if (!this.field) return '';
      return ['Candidate Consent','Candidate Optional Consent'].includes(this.field.type) ?
        this.field.type.replace('Candidate','') :
        this.field.label;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.fieldData = await this.getOptionsMethod(this.field.id);
      this.$set(this.field, 'field_options', this.fieldData.data.field_options);
      this.isLoading = false;
    },
    reset() {
      this.fieldData = null;
    },
    selectField() {
      this.$emit('select:field', {
        field: this.field,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.field-settings {
  &__option {
    &:not(:last-child) {
      @apply border-b;
    }
  }
}
</style>