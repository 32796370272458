<template>
  <div
    class="flex w-full h-8 rounded-lg justify-center items-center bg-blue-400"
  >
    <div class="flex w-8 p-1" v-if="!viewOnlyMode">
      <!-- <font-awesome-icon icon="grip-vertical" class="text-base-content-300 text-gray-500" /> -->
    </div>
    <div class="flex w-full items-center p-1 text-sm rounded-md">
      <div class="flex w-full gap-4">
        <div v-for="(header, idx) in headers" :key="idx" :class="getWidth(header.label)" class="flex">
            <div  class="truncate" :class="{'pl-2': viewOnlyMode}">{{ header.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "status-list-header",
  props: {
    headers: {
      type: Array,
      default: () => [],
      required: true,
    },
    viewOnlyMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    //   headers: [
    //     {
    //       label: "Status Name",
    //       customClass: "col-span-2",
    //     },
    //     {
    //       label: "Description",
    //       customClass: "col-span-2",
    //     },
    //     {
    //       label: "Source",
    //       customClass: "",
    //     },
    //     {
    //       label: "Created At",
    //       customClass: "",
    //     },
    //     {
    //       label: "Last Updated By",
    //       customClass: "",
    //     },
    //     {
    //       label: "Last Updated At",
    //       customClass: "",
    //     },
    //     {
    //       label: "Actions",
    //       customClass: "",
    //     },
    //   ],
    };
  },
  methods: {
    getWidth(label) {
      if(label === "Description" || label === "Default") {
        return `w-3/12`;
      } else if (label === "Actions" || label === "Active") {
        return `w-1/12 justify-center`;
      } else {
        return `w-2/12`;
      }
    }
  }
};
</script>
