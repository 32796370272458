<template>
  <div>
    <div v-if="tab == 'check-output-form-editor'">
      <div
        class="rolePanel flex gap-2.5 justify-end"
        :class="{ checkAdminRole: inCheck }"
      >
        <fieldset
          :disabled="isLoading"
          class="rolePanel__roles flex gap-4"
        ></fieldset>
        <fieldset :disabled="isLoading" class="rolePanel__roles flex gap-4">
          <PanelBlock
            v-for="(role, id) in roles"
            :key="id"
            :field="field"
            class="rolePanel__item"
            :role="role"
            @PermissionChange="PermissionChange"
            @change="change($event, true)"
            :tab="tab"
          />
        </fieldset>
      </div>
    </div>
    <div
      v-else
      class="rolePanel flex gap-2.5"
      :class="{ checkAdminRole: inCheck }"
    >
      <fieldset
        :disabled="isLoading"
        class="rolePanel__roles flex gap-4"
      ></fieldset>
      <fieldset :disabled="isLoading" class="rolePanel__roles flex gap-4">
        <PanelBlock
          v-for="(role, id) in roles"
          :key="id"
          :field="field"
          class="rolePanel__item"
          :role="role"
          @PermissionChange="PermissionChange"
          @change="change($event, true)"
        />
      </fieldset>
      <Checkbox
        @click.native.stop
        @input="changeCaseCreation(field, $event)"
        v-model="useInCaseCreation"
        :disabled="
          !isWritePermissionSelected || this.$route.params.action == 'view'
        "
      />
      <Dropdown
        class="rolePanel__item rolePanel__item--dropdown h-6"
        width="220px"
        placeholder="Assignee"
        @click.native.stop
        @input="change"
        v-model="report_visibility"
        :allowEmpty="false"
        :options="allReportsRoles"
        :config="{ label: 'name', 'track-by': 'id' }"
        openDirection=""
        reposition
        :disabled="this.$route.params.action == 'view'"
        :field="field"
      />
    </div>
  </div>
</template>

<script>
import PanelBlock from "./components/panel-block.vue";
import Dropdown from "@shared/components/dropdown-base";
import Checkbox from "@/components/checkbox-alt";
import { fetchAllRoles } from "@/modules/field-editor/services.js";

import { cloneDeep } from "lodash";
export default {
  name: "field-roles-panel",
  props: {
    tab: {
      type: String,
    },
    field: {
      type: Object,
      default: () => {},
    },
    allReportsRoles: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    openCascadedOption: {
      type: Boolean,
    },
    inCheck: {
      type: Boolean,
    },
  },
  components: {
    PanelBlock,
    Dropdown,
    Checkbox,
  },
  data() {
    return {
      allRoles: [],
      // roles
    };
  },
  computed: {
    roles() {
      let roles_order = {
        Analyst: 0,
        Subject: 1,
        Client: 2,
        Referee: 3,
      };
      let allRoles = this.field.permissions
        ? cloneDeep(this.field.permissions)
        : cloneDeep(this.field.roles);
      allRoles = allRoles.map((role) => ({
        ...role,
        ...this.allRoles.find((rl) => rl.id === role.role_id),
      }));
      const role_list = allRoles.sort((a, b) => {
        return roles_order[a.title] > roles_order[b.title] ? 1 : -1;
      });
      console.log(role_list)
      return role_list || [];
    },
    report_visibility: {
      get() {
        return this.allReportsRoles.find(
          (r) => r.id === this.field.report_visibility
        );
      },
      set(val) {
        this.field.report_visibility = val.id;
      },
    },
    useInCaseCreation: {
      get() {
        return (
          this.roles.find((el) => el.code === "SC-1").role_write &&
          this.roles.find((el) => el.code === "CL-AD").role_write &&
          this.field.use_in_case_creation
        );
      },
      set(val) {
        this.field.use_in_case_creation = val;
      },
    },
    useInBlockVisibility: {
      get() {
        return this._props.field.find((r) => r.block_visibility === true);
      },
      set(val) {
        this.field.block_visibility = val.id;
      },
    },
    isWritePermissionSelected() {
      return (
        this.roles?.find((el) => el.code === "SC-1").role_write &&
        this.roles?.find((el) => el.code === "CL-AD").role_write
      );
    },
  },
  methods: {
    PermissionChange(role) {
      this.field.roles = cloneDeep(this.roles);
      if (this.field.permissions) {
        this.field.permissions = this.field.permissions.map((field) => {
          let roles_rest = cloneDeep(this.roles);
          let role_i = roles_rest.find((item) => item.title === field.title);
          let newField = { ...field };
          newField.role_mandatory = role_i.role_mandatory;
          newField.role_write = role_i.role_write;
          newField.role_read = role_i.role_read;
          newField.role_visible = role_i.role_visible;
          return newField;
        });
      }
      this.$emit("PermissionChange", role);
    },
    change(payload, isRole) {
      if (payload) this.$emit("change", payload, isRole);
    },
    changeCaseCreation(field, $event) {
      this.$emit("changeCaseCreation", { field, event: $event });
    },
    fetchAllRoles,
  },
  async mounted() {
    this.allRoles = await this.fetchAllRoles();
  },
};
</script>

<style lang="scss" scoped>
.checkAdminRole {
  margin-left: 1.8%;
}
@mixin rightDivider() {
  &::after {
    content: "";
    display: block;
    @apply border-l border-solid border-line;
  }
}

.rolePanel {
  max-width: 810px;
  min-width: 810px;
  width: 810px;
  &__item {
    display: flex;

    &:not(:last-child) {
      @include rightDivider;
    }

    &--dropdown {
      // border-color: #CCE3FF;
      // background-color: #CCE3FF;
      @apply bg-primary-100 border-primary-100;
      @apply h-7;

      ::v-deep {
        .multiselect {
          // font-size: 8px;
          &__content-wrapper {
            // position: fixed !important;
          }

          // &__single {
          //   font-size: 8px;
          // }
        }
      }
    }
  }

  &__roles {
    @include rightDivider;
  }
}

::v-deep {
  .checkbox-alt {
    display: flex;
    align-items: center;

    ::v-deep {
      .input-wrapper {
        display: flex;
      }
    }
  }

  .dropdown .multiselect__content-wrapper {
    max-width: 0px;
    border-radius: 8px;
  }
}
</style>
