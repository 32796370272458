const checkShowConf = {
  field: {
    list: 'field_options',
    prop: 'field_label',
  },
  option: {
    list: 'dependents',
    prop: 'option_label',
  },
}

export const checkShow = (searchString, element) => {
  const entity = 'option_label' in element ? 'option' : 'field';
  return !!(element[checkShowConf[entity].prop].toLowerCase().includes(searchString.toLowerCase()) ||
    element[checkShowConf[entity].list]?.some(el => checkShow(searchString, el)));
}