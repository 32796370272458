import axios from "@/axios";

const formatSections = (sections) => {
    let allSections = sections;
    let sectionsList = [];
    allSections?.map((section) => {
        let sectionData = {
            id: section.id,
            name: section.name,
            order: section.order || 50,
            fields: [],
            expanded: false,
        };

        const addToBlock = (field) => {
            let sectionBlock = sectionData.fields.find((el) => el.id === field.block_id);
            if (sectionBlock) {
                sectionData.fields.map((block) => {
                    if (block.id === sectionBlock.id) {
                        block.fields.push(field);
                    }
                });
            } else {
                let newBlock = {
                    id: field.block_id,
                    collect_data_from: field.collect_data_from,
                    block_multi: field.block_details.multi,
                    max_count: field.block_details.max_count,
                    min_count: field.block_details.min_count,
                    name: field.block_details.name,
                    label: field.block_details.label,
                    block_details: field.block_details,
                    fields: [],
                    expanded: false,
                    edited: false,
                };
                newBlock.fields.push(field);
                sectionData.fields.push({ ...newBlock, entity_type: "block" });
            }
        };

        section.fields.map((field) => {
            field = {...field, label: field.label }
            if (field.block_id) {



                addToBlock(field);
            } else {
                sectionData.fields.push({ ...field, entity_type: "field",  });
            }
        });

        sectionsList.push(sectionData);
    });
    return sectionsList;
}


const formatPackageSections = (sections) => {
    let allSections = sections;
    let sectionsList = [];
    allSections?.map((section) => {
        let sectionData = {
            id: section.id,
            name: section.section_name,
            order:  section.section_order  || 50,
            fields: [],
            expanded: false,
            formId: section.form_id,
            from_check: section.from_check
        };

        const addToBlock = (field) => {
            let sectionBlock = sectionData.fields.find((el) => el.id === field.block_id);
            if (sectionBlock) {
                sectionData.fields.map((block) => {
                    if (block.id === sectionBlock.id) {
                        block.fields.push(field);
                    }
                });
            } else {
                let newBlock = {
                    id: field.block_id,
                    block_multi: field.block_details.multi,
                    max_count: field.block_details.max_count,
                    min_count: field.block_details.min_count,
                    name: field.block_details.name,
                    block_details: field.block_details,
                    fields: [],
                    expanded: false,
                    edited: false,
                    from_check: field.block_details.from_check
                };
                newBlock.fields.push(field);
                sectionData.fields.push({ ...newBlock, entity_type: "block" });
            }
        };

        section.fields.map((field) => {
            field = {
                ...field,
                label: field.field_label, 
                from_check: field.from_check
            }
            if (field.block_id) {
                addToBlock(field);
            } else {
                sectionData.fields.push({ ...field, entity_type: "field",  });
            }
        });

        sectionsList.push(sectionData);
    });
    return sectionsList;
}

const fetchAllRoles = async () => {
    let url = `/tenant-check/section/field/valid-roles/`;
    try {
        let { data } = await axios.get(url);
        return data.roles
    } catch (error) {
        console.log("Error:>>>>", error);
    }
}

const fetchReportVisibility = async() => {
    const url = `tenant-check/section/fields/report-visibility/all`;
    try {
        const { data } = await axios(url);
        return data;
    } catch (error) {
        console.log("error :>> ", error);
    }
}

export { formatSections, fetchAllRoles, fetchReportVisibility, formatPackageSections }



