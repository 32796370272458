<template>
  <div
    v-show="isShown"
    class="option-dropdown w-full cursor-pointer"
  >
    <div
      class="option-dropdown__header flex items-center hover:bg-primary-300 px-2"
      :class="{
        'text-sm': sublist,
      }"
      :style="{
        'padding-left': `${8 + level*20}px`
      }"
      @click="toggleOption"
    >
      <div
        v-if="option.is_dependent"
        class="p-1 cursor-pointer text-primary hover:text-primary-focus"
        @click.stop="toggleOption"
      >
        <font-awesome-icon
          icon="chevron-down"
          class="option-dropdown__icon fill-current transition-all"
          :class="{'option-dropdown__icon--expanded': expanded}"
        />
      </div>
      <span class="p-1.5">
        {{ option.option_label }}
      </span>
    </div>
    <div
      v-show="expanded"
      class="option-dropdown__dependents flex flex-col items-center"
    >
      <div
        class="p-2"
        v-if="isLoading"
      >
        <Loader :loading="true" :size="5" />
      </div>
      <template v-else-if="dependents">
        <FieldDropdown
          ref="field"
          v-for="dfield in dependents.data.dependents"
          :key="dfield.id"
          :field="dfield"
          :selectedFieldId="selectedFieldId"
          :getDependentsMethod="getDependentsMethod"
          :getOptionsMethod="getOptionsMethod"
          :level="level + 1"
          :searchString="searchString"
          v-on="$listeners"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Loader from "@shared/loader";
import { checkShow } from './utils';
const FieldDropdown = () => import("./field-dropdown.vue");

export default {
  name: 'option-dropdown',
  props: {
    field: {
      type: Object,
      required: true,
    },
    selectedFieldId: {
      type: String,
    },
    option: {
      type: Object,
      required: true,
    },
    sublist: {
      type: Boolean,
      default: false,
    },
    getDependentsMethod: {
      type: Function,
    },
    getOptionsMethod: {
      type: Function,
    },
    level: {
      type: Number,
      default: 0,
    },
    searchString: {
      type: String,
      default: '',
    },
  },
  components: {
    Loader,
    FieldDropdown,
  },
  data() {
    return {
      expanded: false,
      isLoading: false,
      isLoaded: false,
      dependents: null,
    }
  },
  computed: {
    isShown() {
      return checkShow(this.searchString, this.option);
    },
  },
  methods: {
    async toggleOption() {
      if (this.isLoading || !this.option.is_dependent) return;
      this.expanded = !this.expanded;
      if (!this.isLoaded) {
        this.isLoading = true;
        try {
          this.dependents = await this.getDependentsMethod(this.field.id, this.option.option_value);
          this.$set(this.option, 'dependents', this.dependents.data.dependents);
          this.isLoaded = true;
        } catch (error) {
          console.log(error);
          this.expanded = !this.expanded;
        }
      }
      this.isLoading = false;
    },
    selectOption() {
      this.$emit('select:option', {
        field: this.field,
        option: this.option,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.option-dropdown {
  &__icon {
    &--expanded {
      transform: rotate(180deg)
    }
  }
  &__header {
    &:hover {
      background-color: #D6E9FF;
    }
  }
}
</style>